import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { SingleDatePicker } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { useState, useEffect } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "singledatepicker"
    }}>{`SingleDatePicker`}</h1>
    <ComponentLinks name={'SingleDatePicker'} storybook={'/?path=/story/calendar-calendar--single-date-picker'} vueStorybook={'/?path=/story/calendar-singledatepicker--playground'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=8162%3A50'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`SingleDatePicker – компонент выбора даты в календаре. Содержит компоненты `}<a parentName="p" {...{
        "href": "/src-components-input"
      }}>{`Input`}</a>{` и `}<a parentName="p" {...{
        "href": "/src-components-calendar"
      }}>{`Calendar`}</a>{`.`}</p>
    <p>{`SingleDatePicker контролируемый компонент: для его работы следует передать пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <p>{`SingleDatePicker принимает те же пропы, что и `}<a parentName="p" {...{
        "href": "/src-components-calendar"
      }}>{`Calendar`}</a>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(new Date().toString())\n  return (\n    <div\n      style={{\n        width: \'100%\',\n        height: 150,\n        display: \'flex\',\n        justifyContent: \'center\',\n      }}\n    >\n      <SingleDatePicker\n        yearFrom={1920}\n        yearTo={new Date().getFullYear()}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      SingleDatePicker,
      Playground,
      Props,
      useState,
      useEffect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState(new Date().toString());
        return <div style={{
          width: '100%',
          height: 150,
          display: 'flex',
          justifyContent: 'center'
        }}>
      <SingleDatePicker yearFrom={1920} yearTo={new Date().getFullYear()} value={value} onChange={setValue} mdxType="SingleDatePicker" />
    </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      